import { Language } from 'src/i18n';
import { UserDto, UserResponseDto } from '../../models/user';
import { mapOperatorResponseToOperatorDto, mapOperatorToOperatorResponseDto } from '../operators/operator.map';

export const mapUserResponseToUserDto = (
  dto: UserResponseDto,
  language: Language
): UserDto => ({
  id: dto.id,
  keycloakId: dto.keycloakId,
  username: dto.username,
  firstName: dto.firstName,
  surname: dto.surname,
  email: dto.email,
  telephoneNumber: dto.telephoneNumber,
  driver: dto.driver,
  validFrom: dto.validFrom ? new Date(dto.validFrom) : null,
  validUntil: dto.validUntil ? new Date(dto.validUntil) : null,
  operators: dto.operators?.map(o =>
    mapOperatorResponseToOperatorDto(o, language)
  ),
  fullname: dto.firstName + ' ' + dto.surname,
});

export const mapUserToUserResponseDto = (dto: UserDto): UserResponseDto => ({
  id: dto.id,
  keycloakId: dto.keycloakId,
  username: dto.username,
  tmpPassword: dto.tmpPassword,
  firstName: dto.firstName,
  surname: dto.surname,
  email: dto.email,
  telephoneNumber: dto.telephoneNumber,
  driver: dto.driver,
  validFrom: dto.validFrom?.toISOString(),
  validUntil: dto.validUntil?.toISOString(),
  operators: dto.operators?.map(o =>
    mapOperatorToOperatorResponseDto(o)
  ),
});
