import i18n from 'i18next';
import toast from 'react-hot-toast';
import { Language } from 'src/i18n';
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest
} from '../../api-handler';
import { UserDto, UserResponseDto } from '../../models/user';
import { mapUserResponseToUserDto, mapUserToUserResponseDto } from './user.map';

export const UserApi = {
  current: async () => {
    const { ok, data } = await getRequest<UserDto>('/api/v1/users/current');
    return ok ? ({ ...data } as UserDto) : null;
  },
  getById: async (id: number, language: Language) => {
    const { ok, data } = await getRequest<UserResponseDto>(`/api/v2/Users/${id}`);

    return ok ? mapUserResponseToUserDto(data, language) : null;
  },
  createUser: async (c: UserDto) => {
    const user = mapUserToUserResponseDto(c);
    const { ok, data, error } = await postRequest<number>('/api/v2/Users', user);
    if (ok) {
      toast.success(`${i18n.t('user.singular')} ${i18n.t('crud.created')}`);
      return { ...user, id: data } as UserDto;
    } else if (error?.statusCode === 409) { // 409 conflict
      toast.error(i18n.t('user.userAlreadyExists'));
      return null;
    } else {
      toast.error(`${i18n.t('crud.createdFailed')}:${error?.message}`);
      return null;
    }
  },
  editUser: async (id: number, c: UserDto) => {
    const user = mapUserToUserResponseDto(c);
    const { ok, error } = await putRequest(`/api/v2/Users/${id}`, user);
    if (ok) {
      toast.success(`${i18n.t('user.singular')} ${i18n.t('crud.updated')}`);
      return user;
    } else {
      toast.error(`${i18n.t('crud.updateFailed')}:${error?.message}`);
      return null;
    }
  },
  deleteUser: async (id: number, user: UserDto) => {
    const { ok, error } = await deleteRequest(`/api/v1/users/${id}`, user);
    if (ok) {
      toast.success(`${i18n.t('user.singular')} ${i18n.t('crud.deleted')}`);
      return id;
    } else {
      toast.error(`${i18n.t('crud.deleteFailed')}:${error?.message}`);
      return null;
    }
  },
  resetPassword: async (id: number, newPassword: string) => {
    const { ok, error } = await putRequest(`/api/v2/Users/${id}/ResetPassword`, {
      temporary: false,
      password: newPassword
    });
    if (ok) {
      toast.success(i18n.t('user.passwordUpdated'));
    } else {
      toast.error(`${i18n.t('user.resetPasswordError')}: ${error?.message}`);
    }
  }
};
