import { UserRole } from 'src/data/models/user';
import { Paths } from 'src/routes';
import { TranslationKey } from 'src/typings/translation';

export type NavItem = {
  name: TranslationKey;
  icon: string;
  route?: string;
  disabled?: boolean;
  roles?: UserRole[];
  children?: NavItemChild[];
};

export type NavItemChild = {
  name: TranslationKey;
  route: string;
  disabled?: boolean;
  roles?: UserRole[];
};

export function isNavItem(item: NavItem | NavItemChild): item is NavItem {
  return 'icon' in item;
}

export const getNavItems = (): NavItem[] => [
  {
    name: 'dashboard',
    route: Paths.base,
    icon: 'la-tachometer-alt'
  },
  {
    name: 'network.transport',
    icon: 'la-network-wired',
    roles: ['superadmin'],
    children: [
      {
        name: 'stop.plural',
        route: Paths.stops.manage
      },
      {
        name: 'network.definition',
        route: Paths.networks.datasets
      },
      {
        name: 'network.active',
        route: Paths.activenetwork.activeNetworkTree
      },
      {
        name: 'zone.plural',
        route: Paths.zones.manage
      }
    ]
  },
  {
    name: 'fareManagement.singular',
    icon: 'la-money-bill-wave',
    roles: ['superadmin'],
    children: [
      {
        name: 'fareManagement.profiles',
        route: Paths.travellerProfiles.manage
      },
      {
        name: 'fareManagement.productsAndPricing',
        route: Paths.productsPricing.zonePricingDefinitions
      },
      {
        name: 'fareManagement.salesChannels',
        route: Paths.salesChannels.manage
      },
      {
        name: 'fareManagement.salesRules',
        route: Paths.salesRules.overbooking
      },
      {
        name: 'shift.plural',
        route: Paths.shifts.reports
      },
      {
        name: 'fareManagement.salesEquipment',
        route: Paths.salesEquipment.salesEquipmentDevices
      }
    ]
  },
  {
    name: 'ticket.plural',
    icon: 'la-ticket-alt',
    roles: ['superadmin', 'washing_line'],
    children: [
      {
        name: 'crud.view',
        roles: ['superadmin'],
        route: Paths.tickets.base
      },
      {
        name: 'ticket.sell',
        roles: ['superadmin'],
        route: Paths.tickets.sell
      },
      {
        name: 'ticket.departures',
        roles: ['superadmin', 'washing_line'],
        route: Paths.tickets.departures.base
      },
      {
        name: 'ticket.layout',
        roles: ['superadmin'],
        route: Paths.tickets.ticketLayout
      }
    ]
  },
  {
    name: 'customer.plural',
    route: Paths.customers.manage.base,
    icon: 'la-male',
    roles: ['superadmin']
  },
  {
    name: 'vehicle.plural',
    icon: 'la-bus',
    roles: ['superadmin'],
    children: [
      {
        name: 'vehicle.plural',
        route: Paths.vehicles.base
      },
      {
        name: 'vehicleType.plural',
        route: Paths.vehicleTypes.base
      },
      {
        name: 'vehiclePlanning.singular',
        route: Paths.vehiclePlanning.base
      }
    ]
  },
  {
    name: 'report.plural',
    icon: 'la-file-alt',
    roles: ['superadmin', 'economy'],
    children: [
      {
        name: 'report.financial',
        route: Paths.reports.financial.base
      },
      {
        name: 'report.operations',
        route: Paths.reports.operations.base
      }
    ]
  },
  {
    name: 'support',
    icon: 'la-question-circle',
    roles: ['superadmin', 'support'],
    children: [
      {
        name: 'ticket.plural',
        route: Paths.tickets.base
      },
      {
        name: 'customer.plural',
        route: Paths.customers.manage.base
      },
      {
        name: 'departure.plural',
        route: Paths.tickets.departures.base
      }
    ]
  },
  {
    name: 'user.plural',
    icon: 'la-users',
    roles: ['superadmin'],
    children: [
      {
        name: 'verb.manage',
        route: Paths.users.manage.base
      },
      {
        name: 'user.access',
        route: Paths.users.access.rights
      }
    ]
  },
  {
    name: 'setting.plural',
    icon: 'la-cog',
    roles: ['superadmin'],
    children: [
      {
        name: 'language.plural',
        route: Paths.settings.languages
      },
      {
        name: 'setting.visualSettings',
        route: Paths.settings.visual
      },
      {
        name: 'setting.messageTemplates',
        route: Paths.settings.messageTemplates
      },
      {
        name: 'setting.otherSettings',
        route: Paths.settings.other
      }
    ]
  }
];
